const topsales = {
  title: 'Najbolj popularno',
  items: [
    {
      id: '0p0x1',
      img: 'https://petexpress.si/cdn/shop/products/mehka-odeja-z-rokavi-pink-709380_2048x2048.jpg?v=1694960036',
      title: 'Hoodie Blanket plišasta odeja z rokav',
      description:
        'Predstavljamo vam izdelek, ki vas bo očaral in zasvojil - našo najnovejšo stvaritev, Najmehkejšo in Najudobnejšo Odejo, ki je trenutno na voljo v naši spletni trgovini. Prepričani smo, da vas bo ta odeja navdušila, kot še nobena doslej!',
      category: 'Izdelki za dom',
      old_price: '49,90',
      price: '30,90',
      link: 'https://petexpress.si/products/mehka-plisasta-odeja-z-rokavi',
      store: 'Petexpress',
    },
    {
      id: '0p0x2',
      img: 'https://petexpress.si/cdn/shop/products/valjcek-za-pobiranje-pasjih-in-macjih-dlak-pink-394099_2048x2048.jpg?v=1681150954',
      title: 'Valjček za pobiranje pasjih in mačjih dlak Pink',
      description:
        'Pasje in mačje dlake sčasoma postanejo najlepši modni dodatek na tvojih oblačilih a v določenih situacijah tak modni dodatek ni sprejemljiv. Z našim valjčkom jih lahko zlahka očistiš s svojih oblačil, preproge ali kavča. Je zelo lahek in ga lahko vzamete s seboj na potovanje ali v službo.',
      category: 'Hišni ljubljenčki',
      old_price: '24,90',
      price: '14,90',
      link: 'https://petexpress.si/collections/aktualne-ponudbe/products/valjcek-za-pobiranje-pasjih-in-macjih-dlak-pink',
      store: 'Petexpress',
    },
    {
      id: '0p0x3',
      img: 'https://petexpress.si/cdn/shop/products/cistilec-pasjih-tack-velikosti-s-m-xl-624941_2048x2048.jpg?v=1681148704',
      title: 'Čistilec pasjih tačk: velikosti S, M, XL',
      description:
        'Ste tudi vi utrujeni od vsakodnevnega čiščenja odtisov pasjih tačk po stanovanju? Olajšajte si čiščenje z našim čistilcem za pasje in mačje tačke',
      category: 'Hišni ljubljenčki',
      old_price: '21,90',
      price: '12,90',
      link: 'https://petexpress.si/collections/najbolj-prodajano-jesen-zima/products/cistilec-pasjih-tack',
      store: 'Petexpress',
    },
    {
      id: '0p0x4',
      img: 'https://petexpress.si/cdn/shop/products/gobica-za-odstranjevanje-madezev-magic-sponge-652733_2048x2048.jpg?v=1681151331',
      title: 'Gobica za odstranjevanje madežev Magic Sponge 8+2 gratis',
      description:
        'Čarobna gobica dobro deluje doma pri vseh čiščenjih, kot so čiščenje ploščic, fug, čiščenje kuhalnikov in sanitarij. Z uporabo čarobne gobice bomo očistili tudi oblazinjenje, steklo in plastiko. Z lahkoto odstranjuje madeže prahu, flomastrov, svinčnika ali voščenk',
      category: 'Vse za dom',
      old_price: '20,90',
      price: '11,90',
      link: 'https://petexpress.si/collections/najbolj-prodajano-jesen-zima/products/gobica-za-odstranjevanje-madezev-magic-sponge',
      store: 'Petexpress',
    },
  ],
};

const featured = {
  title: 'Aktualna Ponudba',
  description:
    'Tu boste našli najboljše ponudbe s številnimi popusti in kupončki trgovin',
  items: [
    {
      id: '0p0x1',
      img: 'https://petexpress.si/cdn/shop/products/mehka-odeja-z-rokavi-pink-709380_2048x2048.jpg?v=1694960036',
      title: 'Hoodie Blanket plišasta odeja z rokav',
      description:
        'Predstavljamo vam izdelek, ki vas bo očaral in zasvojil - našo najnovejšo stvaritev, Najmehkejšo in Najudobnejšo Odejo, ki je trenutno na voljo v naši spletni trgovini. Prepričani smo, da vas bo ta odeja navdušila, kot še nobena doslej!',
      category: 'Izdelki za dom',
      old_price: '49,90',
      price: '30,90',
      link: 'https://petexpress.si/products/mehka-plisasta-odeja-z-rokavi',
      store: 'Petexpress',
    },
    {
      id: '0p0x2',
      img: 'https://petexpress.si/cdn/shop/products/valjcek-za-pobiranje-pasjih-in-macjih-dlak-pink-394099_2048x2048.jpg?v=1681150954',
      title: 'Valjček za pobiranje pasjih in mačjih dlak Pink',
      description:
        'Pasje in mačje dlake sčasoma postanejo najlepši modni dodatek na tvojih oblačilih a v določenih situacijah tak modni dodatek ni sprejemljiv. Z našim valjčkom jih lahko zlahka očistiš s svojih oblačil, preproge ali kavča. Je zelo lahek in ga lahko vzamete s seboj na potovanje ali v službo.',
      category: 'Hišni ljubljenčki',
      old_price: '24,90',
      price: '14,90',
      link: 'https://petexpress.si/collections/aktualne-ponudbe/products/valjcek-za-pobiranje-pasjih-in-macjih-dlak-pink',
      store: 'Petexpress',
    },
    {
      id: '0p0x3',
      img: 'https://petexpress.si/cdn/shop/products/cistilec-pasjih-tack-velikosti-s-m-xl-624941_2048x2048.jpg?v=1681148704',
      title: 'Čistilec pasjih tačk: velikosti S, M, XL',
      description:
        'Ste tudi vi utrujeni od vsakodnevnega čiščenja odtisov pasjih tačk po stanovanju? Olajšajte si čiščenje z našim čistilcem za pasje in mačje tačke',
      category: 'Hišni ljubljenčki',
      old_price: '21,90',
      price: '12,90',
      link: 'https://petexpress.si/collections/najbolj-prodajano-jesen-zima/products/cistilec-pasjih-tack',
      store: 'Petexpress',
    },
    {
      id: '0p0x4',
      img: 'https://petexpress.si/cdn/shop/products/gobica-za-odstranjevanje-madezev-magic-sponge-652733_2048x2048.jpg?v=1681151331',
      title: 'Gobica za odstranjevanje madežev Magic Sponge 8+2 gratis',
      description:
        'Čarobna gobica dobro deluje doma pri vseh čiščenjih, kot so čiščenje ploščic, fug, čiščenje kuhalnikov in sanitarij. Z uporabo čarobne gobice bomo očistili tudi oblazinjenje, steklo in plastiko. Z lahkoto odstranjuje madeže prahu, flomastrov, svinčnika ali voščenk',
      category: 'Vse za dom',
      old_price: '20,90',
      price: '11,90',
      link: 'https://petexpress.si/collections/najbolj-prodajano-jesen-zima/products/gobica-za-odstranjevanje-madezev-magic-sponge',
      store: 'Petexpress',
    },
  ],
};

export { topsales, featured };
